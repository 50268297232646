const apiUrl = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL : 'api.lern.papionne.com';
export const API_HOST = apiUrl + '/v1';
export const OAUTH_HOST = apiUrl;
export const API_PROTOCOL = process.env.NEXT_PUBLIC_API_PROTOCOL ? process.env.NEXT_PUBLIC_API_PROTOCOL : 'http';

export const API_PUBLIC_TOKEN_GRANT_TYPE = 'client_credentials';
export const API_REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
export const API_LOGIN_GRANT_TYPE = 'password';
export const API_PUBLIC_TOKEN_SCOPE = '*';
export const API_CLIENT_SECRET = process.env.NEXT_PUBLIC_CLIENT_SECRET;
export const API_CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID;

export const CONNECT_WITH_STRIPE_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_STRIPE_APP_CLIENT_ID}&scope=read_write`;
